/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Oct2824: IPost = {
  id: 13,
  title: "Anapanasati Day 3",
  date: new Date("2024/10/28"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        <h2>Zen intermission</h2>
        <div>
          Took a break from Anapanasati to meditate at the local zen Buddhist
          temple. The largest difference I find is that my mind wanders more in
          Zen, due to the lack of a meditation object to focus on.
        </div>
        <br></br>
        <div>
          This of course is not really a problem, just something to take note
          of.
        </div>
      </div>
      <h2>Anapanasati Day 3</h2>
      <div>
        Once again focused on the first three steps of Anapanasati: in-breath,
        out-breath, and bodily sensations. I focused only briefly on the first
        two steps before moving on to the third. I think this resulted in an
        impairment on my concentration. I caught brief glimpses of deep
        concentration and relaxation of the body, but these lasted only
        momentarily.
      </div>
      <br></br>
      <div>
        All the usual mental formations (sankaras) kept bubbling up - for
        instance thinking about what I am going to write about in my blog post
      </div>
      <br></br>
      <div>Tomorrow I think I will focus entirely on the first two steps.</div>
    </>
  ),
};
