import { useParams } from "react-router-dom"
import { Post } from "./post"
import { Posts } from "./posts"


export function Blog() {
    let {tag} = useParams()
    return(
    <>
    <h1>My Blog</h1>
    <div style={{display:'flex'}}>
        <div>
        {
            Posts.filter((post)=>tag ? post.tags.includes(tag) : true).map((post) => <Post post={post}/>)
        }
        </div>
    </div>
    </>)
}