import { IPost } from "../types";

export let Nov1323: IPost = {
    'id': 0,
    'title': 'Hello World',
    'date': new Date('2023/11/13'),
    'tags': ['Journal'],
    'body':
<>
<div>
    <p>
        Reading: <a href='https://en.wikipedia.org/wiki/House_of_Leaves'><span style={{'color':"#0590ed"}}>House</span> of Leaves</a>
        <br></br>
        Playing: Story of Seasons: A Wonderful Life
        <br></br>
        Mood: Focused
    </p>
    <div style={{'display':'flex'}}>
        <div style={{'flexGrow': 3}}>
            <p>
                I have a website! You remember websites, don't you? The modern web seems to be increasingly condensed
                into just a few social media sites owned by an even fewer number of companies. But, that doesn't 
                mean the 'old web' has to die. After all, anyone can make their own website! In particular, <a href="https://neocities.org">neocities.org</a> seems super cool. That's where I would have made my
                website if I didn't do it on AWS. Long live the old web!
            </p>
            <p>
                I intend to use this space as a journal to record my thoughts and post my hilarious jokes. It's more for me 
                than anyone else but if you are here then hello! 
            </p>
            <p>
                It's autumn now, and for peak autumn vibes my Song of the Day is:
                <br></br> 
                <iframe title='In a Sweater Poorly Knit' style={{'border':'0','width':'400px','height':'42px' }} src="https://bandcamp.com/EmbeddedPlayer/album=366003187/size=small/bgcol=000000/linkcol=ffffff/track=966100154/transparent=true/" seamless><a href="https://mewithoutyou.bandcamp.com/album/brother-sister">Brother, Sister by mewithoutYou</a></iframe>
                <blockquote>
                    If I'm a crown without a king <br></br>
                    If I'm a broken open seed <br></br>
                    If I come without a thing<br></br>
                    Then I come with all I need<br></br>
                </blockquote>
            </p>
        </div>
        <div>
            <img style={{'flexGrow': 1}} src="/assets/images/dancingbaby.gif" alt="Dancing baby gif" height="200px"></img>
            <p style={{'fontSize': '14px'}}>Look at this funny .GIF I found! It gives my blog a modern flair, right?</p>
        </div>
    </div>
</div>
</>
}