/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Oct2524: IPost = {
  id: 11,
  title: "Anapanasati Day 1",
  date: new Date("2024/10/25"),
  tags: ["Journal", "Meditation", "Longpost"],
  body: (
    <>
      <div>
        Very productive meditation today. I set the timer for 40 minutes and sat
        down to meditate. I have a very comfortable meditation bench. Actually,
        I decided my meditation bench was TOO comfortable so I switched to a
        meditation cushion so I could experience my legs falling asleep during
        meditation.
      </div>
      <div>
        I focused on the first two instructions of Anapanasati: notice when the
        in-breath is long or short, and notice when the out-breath is long or
        short.
      </div>
      <div>
        At first I noticed all sorts of details about my breath, whether they
        were shuddering or smooth, shallow or deep. It takes some practice to be
        able to watch the breath without controlling it, but once you do it's
        interesting to see how each breath is subtly different
      </div>
      <div>
        I found myself forming a preference for long, smooth breaths. I noted
        this preference and shifted my focus to simply noting each breath
        without judgement
      </div>
      <div>
        After some time I went back to the original instruction, just noting if
        the breath is long or short. Mentally saying the words 'long' and
        'short' was very helpful for maintaining my focus.
      </div>
      <div>
        After some time my leg started to fall asleep. It became quite
        uncomfortable. My attention shifted to the third step, which is
        awareness of body sensations.
      </div>
      <div>
        My mind became restless. I wanted to check the timer to see how much
        time was left. I wanted to stop.
      </div>
      <div>
        But, it immediately became apparent that the only way I was going to
        make it to the end of the sit was by setting aside all other thoughts
        and making space in my mind for the discomfort. When I stopped thinking
        about the timer, when I stopped labelling my breaths, there was more
        room to hold the discomfort. The discomfort was there but bearable. When
        my mind wandered it became less bearable. When it focused on the
        discomfort it became more bearable.
      </div>
      <div>
        An important concept in meditation is 'scale invariance'. The small
        patterns we see in our minds on the cushion are the same patterns our
        mind goes to out in the world. Labelling the breaths without judging
        them 'good' or 'bad' helps the mind go through experiences without
        labelling them 'good' or 'bad'. Sitting with an uncomfortable leg trains
        the mind to react to uncomfortable experiences by making space for them,
        not running away from them.
      </div>
      <div>When the bell rang my foot was completely numb</div>
    </>
  ),
};
