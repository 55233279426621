/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Oct3024: IPost = {
  id: 15,
  title: "Anapanasati Day 5",
  date: new Date("2024/10/30"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        Today I sat down determined to make progress on my goal of watching the
        breath without influencing it. I tried letting myself get distracted so
        I could see what the breath was doing when it didn't realize it was
        being watched. I worried over if I was really breathing automatically,
        or if I was subtly deciding to switch from inhale to exhale based on
        bodily cues. With each breath I carefully judged whether it was really
        happening without my influence or not
      </div>
      <br></br>
      <div>
        Then I realized I was doing the exact thing I was trying to avoid, which
        is influence the breath. I was trying to think my way through something
        that could not be solved by thinking. It's scale invariance again; this
        is a pattern that repeats itself in my life off the cushion. I get
        caught in traps trying to think my way to a solution to something that
        can't be solved by thinking, and so my mind just wanders in circles
        searching for something that can't be found
      </div>
    </>
  ),
};
