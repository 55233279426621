/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Oct3124: IPost = {
  id: 16,
  title: "Anapanasati Day 6",
  date: new Date("2024/10/31"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        Turns out focusing is much easier when I remember to take my adderall.
        Today I observed my breath, and my mind was not full of thoughts about
        how to observe the breath. I descended into a fairly deep concentration,
        and then my mind naturally gravitated towards step 3 of Anapanasati,
        which is focus on bodily sensations.
      </div>
      <div>
        Shortly after switching to focus on bodily sensations, my focus waned
        and I brought focus back to the breath. Towards the end of the
        meditation I felt myself grow restless.
      </div>
      <div>
        I've sat on my cushion enough times that it's flattened out and now my
        legs don't fall asleep as badly.
      </div>
    </>
  ),
};
