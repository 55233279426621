import { IPost } from "./types"
export function Post(props: {post:IPost}) {
    let {post} = props
    return (
        <>
        <hr></hr>
        <a href={`/blog/${post.id}`}><h2 style={{margin: 0}}>{post.title}</h2></a>
        {post.date.toDateString()} <br></br>
        Tags: {post.tags.map((tag, index) => {return <><a href={`/blog/tagged/${tag}`}>{tag}</a>{index < post.tags.length - 1 && ', '}</>})}
        {post.body}
        </>
    )
}