import { useParams } from "react-router-dom";
import { Post } from "./post";
import { Posts } from "./posts";

export function SinglePost(props: {}) {
    let {id} = useParams()
    console.log(`id is ${parseInt(id??'0', 10)}`)
    return <>
        <Post post={Posts.slice().reverse()[parseInt(id??'0', 10)]}></Post>
    </>
}