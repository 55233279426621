import React from "react";
import "./App.css";
import { MainComponent } from "./main";

function canvasHook(canvas: any) {
  // code goes here
}

function App() {
  return (
    <div className="App">
      <MainComponent></MainComponent>
    </div>
  );
}

export default App;
