/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Oct2424: IPost = {
  id: 10,
  title: "Anapanasati",
  date: new Date("2024/10/24"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        Ānāpānasati is the source of much discussion, because it is the method
        of meditation the Buddha used to attain enlightenment. The method is
        documented in the Ānāpānasati Sutta, and lists 16 steps
      </div>
      <div>
        <ol>
          <li>Contemplating the in-breath</li>
          <li>Contemplating the out-breath</li>
          <li>Contemplating the whole body</li>
          <li>Calming the body</li>
          <li>Experiencing joy/rapture</li>
          <li>Experiencing pleasure</li>
          <li>Experiencing mental formation</li>
          <li>Calming mental formation</li>
          <li>Experiencing the mind</li>
          <li>Pleasing the mind</li>
          <li>Concentrating the mind</li>
          <li>Releasing the mind</li>
          <li>Observing impermanence</li>
          <li>Observing dispassion</li>
          <li>Observing cessation</li>
          <li>Observing relinquishment</li>
        </ol>
      </div>
      <div>
        I will focus on this particular meditation practice for the next week
        and record my experiences
      </div>
    </>
  ),
};
