/* eslint-disable jsx-a11y/img-redundant-alt */
import { Stack } from "@mui/material";
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Oct2924: IPost = {
  id: 14,
  title: "Anapanasati Day 4",
  date: new Date("2024/10/29"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        <Stack direction="row">
          <div>
            <div>
              Focused today entirely on labelling breaths as either 'long' or
              'short'. It became clear that I was still subtly influencing the
              breath, favoring longer breaths. It is really very challenging to
              notice the breath without influencing it. I noticed when my mind
              wandered, my breathing went back to automatic, and the breaths
              seemed to be shorter.
            </div>
            <br></br>
            <div>
              In the Great Mindfulness Arousing Discourse (Mahasatipatthana
              Sutta, DN 22) the Buddha makes the following metaphor
            </div>
            <blockquote>
              Just as a skillful turner or turner's apprentice, making a long
              turn, knows, "I am making a long turn," or making a short turn,
              knows, "I am making a short turn," just so the monk, breathing in
              a long breath, knows, "I am breathing in a long breath"; breathing
              out a long breath, he knows, "I am breathing out a long breath";
              breathing in a short breath, he knows, "I am breathing in a short
              breath"; breathing out a short breath, he knows, "I am breathing
              out a short breath."
            </blockquote>
            <br></br>
            <div>
              Thus it seems as though the breath should move as though through
              muscle memory. You would think I would have established muscle
              memory for breathing, given that I've been doing it nearly all my
              life. But I suppose the new skill is breathing while watching the
              breath. I shall keep practicing
            </div>
          </div>
          <ImageCard
            url="/assets/images/manual_breathing.jpg"
            altText="Text that says 'You are now breathing manually'"
            height="200px"
          />
        </Stack>
      </div>
    </>
  ),
};
