/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { Snake } from "../../games/snake";
import { IPost } from "../types";

export let Jun2124: IPost = {
    'id': 8,
    'title': 'Blog Template',
    'date': new Date('2024/6/21'),
    'tags': ['Journal', 'Devlog'],
    'body':
<>
<div>
    <p>
        Reading: The Collected Schizophrenias
        <br></br>
        Playing: Pikmin 4
        <br></br>
        Mood: Stir-crazy
    </p>

    <div style={{'display':'flex'}}>
        <div style={{'flexGrow': 3}}>
            <p>
                I'm on day 10 of a wretched summer cold and let me tell you I am having a Bad Time. 
                Summer is a time for playing outside and eating popsicles and and and, I dunno,
                splashing around in the lake? I've been inside so long I can't even remember. 
            </p>
            <p>
                But at least it gives me time to work on my website, and let me tell you, I've 
                made some big changes! Like, the tags actually work! Go on, try it! And, I've written
                a template for my blog posts so I can use <a href='https://www.npmjs.com/package/generate-template-files'>generate-template-files </a> 
                to write up all the boilerplate for me! No more copypasting! I simply call <code>npm run generate </code>
                and the templating engine does the following:
                <ul>
                    <li>Picks today's date</li>
                    <li>Uses the date to calculate the filename, file path, and classname</li>
                    <li>Insert the new class into my list of blog posts that I use instead of a real database</li>
                </ul>
                Voila! Magic!
            </p>
            <p>
                <i>Why</i> go through all this trouble instead of, I dunno, using a pre-existing blogging engine 
                like a sane person? Well, it lets me write blog posts in pure React code! It's so flexible!
                I can do anything!! Do you want snake? You got snake!
            </p>
            <p><Snake/></p>
            <p>
                If you're done playing snake, let me treat you to this song that has been bouncing around my head all day like a dvd player screensaver. Song of the day: Tally Hall - Spring and a Storm
                <br></br> 
                <iframe  height="200" src="https://www.youtube.com/embed/8ERupp4cMU4?si=CCnGZeLIUWmAh5ll" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                
                <blockquote>
                    The sky is deep and dark and eternally high <br></br>
                    Many people think that's where you go when you die <br></br><br></br>
                    Do you?<br></br><br></br>
                    Well I think you return to obscure <br></br>
                    Or wherever you were <br></br>
                    Before you were <br></br>
                    But I won't let you lose yourself <br></br>
                    In the rain <br></br>
                </blockquote>
            </p>
            
        </div>
        <ImageCard 
            height='200px'
            url="/assets/images/blogTemplate.png" 
            altText="A screenshot of a template file for a blog post"
            caption="Boilerplate, meet templating engine"/>
    </div>
</div>
</>
}