/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Nov524: IPost = {
  id: 20,
  title: "Anapanasati Day 10",
  date: new Date("2024/11/5"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        Sat Anapanasati again. Noted that the breaths started out short and then
        naturally became longer. Had problems with neck pain and my posture
        again. Maintaining proper posture without tension takes a lot of
        practice.
      </div>
      <div>
        Tried the third step again of awareness of body sensations. My awareness
        was like a spotlight, flitting from sensation to sensation. I want my
        awareness to be more like lantern awareness, with equal focus on all
        body sensations. But perhaps I am overthinking it.
      </div>
      <div>I will keep practicing.</div>
    </>
  ),
};
