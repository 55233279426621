/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Nov124: IPost = {
  id: 17,
  title: "Anapanasati Day 7",
  date: new Date("2024/11/1"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        Started off as usual noting which breaths were long and which were
        short. Thoughts were occurring in the background but they didn't really
        bother me. Eventually my focus naturally drifted to the sensations of
        the entire body.
      </div>
      <br></br>
      <div>
        It seemed easier to hold the many sensations of the body in my mind.
        Notably I had discomfort in my neck and legs but the discomfort didn't
        bother me. My attention waned after sometime and my focus drifted back
        to my thoughts.
      </div>
      <br></br>
      <div>
        I think my perception of time shifts during these periods of intense
        concentration, because I thought for sure that my meditation must be
        almost over, but it was still some time before the bell rang. I rounded
        off the end of the meditation by shifting to Metta (lovingkindness).
      </div>
      <div>
        It's easiest to conjure up metta for beings you are close to (my cat in
        my instance is usually my first external metta object), but actually my
        favorite part is extending metta to strangers and acquaintences. It
        helps me feel more connected with the world.
      </div>
    </>
  ),
};
