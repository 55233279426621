/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Nov424: IPost = {
  id: 18,
  title: "Anapanasati Day 9",
  date: new Date("2024/11/4"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        My experiment with Anapanasati has gone over the week, and I imagine it
        will last sometime more.
      </div>
      <div>
        Today started off as usual, noting the long and short breaths and then
        shifting awareness to bodily sensations. This time I got distacted by a
        pain in my neck I had been experiencing for quite some time, and focus
        on my posture. I noticed my posture would slip with every out breath,
        and my shoulders would tense up with every in-breath. I suppose this is
        a natural outcome of shifting attention to the body.
      </div>
    </>
  ),
};
