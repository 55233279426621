import { IPost } from "../types";

export let Jan0324: IPost = {
    'id': 5,
    'title': 'January',
    'date': new Date('2023/1/3'),
    'tags': ['Journal'],
    'body':
<>
<div>
    <p>
        Reading: Disability Visibility
        <br></br>
        Playing: Against the Storm
        <br></br>
        Mood: Ill
    </p>
    <div style={{'display':'flex'}}>
        <div style={{'flexGrow': 3}}>
            <p>
                Happy new year everybody. I've started off the new year by catching a terrible cold. I might take a rain check for this one folks, 
                see you all in 2025!
            </p>
            <p>
                Have you ever played a game a really long time ago that really stuck with you for no reason whatsoever? For me it was a text 
                game I got on a CD of 50 PC games at Best Buy. I think I picked it out cause it had more games than any other game pack at Best Buy.
                Most of the games were shovelware demos of course, but one that stuck out to me was a pack of 5 text-based adventures. As a kid
                who grew up on the N64, the idea of a text-based adventure thrilled me. You're telling my I can do ANYTHING in this game 
                just by typing it out? Never mind that there were only a handful of commands that were accepted by the game, I didn't 
                know that. All I knew was that the world was at my fingertips. "Go north". "Pick up stick". "Become god"
            </p>
            <p>
                The one text game I remember the most was called Tower of Babel. You play as a character who woke up in an abandoned
                laboratory. You find notes to piece together what happened, and I think you end up injecting yourself with a steroid or something to 
                gain the strength to open barred doors, or something like that. I never actually beat it. But for some reason I still remember that game
            </p>
            <p>
                Was I going anywhere with this story? No, no I was not.
            </p>
            <p>
                I think there is no better way to go into January than with this, the only Decemberist's song that isn't about murder.
            </p>
            <p>
                <br></br> 
                <iframe height="200" src="https://www.youtube.com/embed/XqDlTKqxu2w?si=sgwvbEqbULxNVWsU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <blockquote>
                    On a winter's Sunday I go<br></br>
                    To clear away the snow<br></br>
                    And green the grounds below<br></br>
                    April all an ocean's away<br></br>
                    Is this the better way to spend the day?<br></br>
                    Keeping the winter at bay<br></br>
                </blockquote>
            </p>
        </div>
        <div>
            <img style={{'flexGrow': 1}} src="assets/images/happy_new_year.gif" alt="Happy new Year spinning text" height="200px"></img>
            <p style={{'fontSize': '14px'}}>Here's to an amazing 2001!</p>
        </div>
    </div>
</div>
</>
}