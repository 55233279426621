/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Stack } from "@mui/material";
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Jul2624: IPost = {
  id: 9,
  title: "Cloud Mountain Retreat",
  date: new Date("2024/7/26"),
  tags: ["Journal", "Longpost"],
  body: (
    <>
      <div>
        <p>
          Reading: An Immense World
          <br></br>
          Playing: The Talos Principle 2<br></br>
          Mood: Calm
        </p>
        <Stack>
          <Stack direction="row">
            <Box>
              <h2>Introduction</h2>
              <p>
                I love being quiet. It's one of my favorite things to do. I also
                love being out in nature. So, when my friend Ben asked if I
                wanted to go sit in silence in the woods for a week, I responded
                that he made a very compelling case. After some deliberation I
                decided to pack up my bags and fly out to Seattle.
              </p>
              <p>
                That's how I ended up attending a 6-day silent meditation
                retreat at{" "}
                <a href="https://cloudmountain.org/">
                  Cloud Mountain Retreat Center
                </a>
                . The retreat I attended was the young adult's retreat for ages
                18-35 (it's nice to be considered a young adult in some contexts
                still!). The retreat was lead by two young teachers,{" "}
                <a href="https://cloudmountain.org/profile/keri-pederson/">
                  Keri Pederson (she/her)
                </a>{" "}
                and{" "}
                <a href="https://cloudmountain.org/profile/shelly-graf/">
                  Shelly Graf (they/them)
                </a>
                .
                <p>
                  The format of the retreat is simple: arrive at the retreat
                  center, relinquish all electronics, and enter noble silence
                  for the next six days, spending most of the day meditating.
                  The retreat center is located out in a beautiful old growth
                  redwood forest, so needless to say I was very excited.
                </p>
              </p>
            </Box>
            <ImageCard
              url="/assets/images/stellars_jay_shrine.png"
              altText="A stone shrine of Buddha decorated with various offerings, including 5 blue-and-black feathers"
              caption="I found two Stellar's Jay feathers and offered them to this statue! We had five total by the end of the retreat"
            />
          </Stack>
          <Stack direction="row" alignItems={"center"}>
            <ImageCard
              height="200px"
              url="/assets/images/ame_wolf.jpeg"
              altText="A screenshot of an animated movie. A wolf with humanlike eyes stands with a sweater wrapped around its neck"
              caption="If you haven't seen it, you should. It's a very good movie!"
            />
            <Box>
              <h2>Day Zero</h2>
              <p>
                On the first day immediately after I arrived, I walked up the
                hill away from the buildings and out into the quiet nature.
                Slowly, I felt my entire body unclench. Tension I had been
                holding in my arms for I don't know how long faded away. I had
                been away from nature for so long. For the first time in what
                felt like months, I felt my body completely and totally relax.
              </p>
              <p>
                Then I heard the sound of voices coming from down the hill, and
                all the tension came flooding back. The sound of other people
                caused every muscle in my body to tense. Have you ever seen the
                movie "Wolf Children: Ame and Yuki"? In that moment I had an
                overwhelming urge to turn into a wolf and run away into the
                forest never to return.
              </p>
              <p>
                I still haven't figured out how to do that so I went back down
                and signed in for the retreat.
              </p>
            </Box>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Box>
              <h2>Day One</h2>
              <p>
                I woke up on the first real day filled with trepidation. I
                seriously was not looking forward to the presence of other
                humans, but with the silence officially beginning I wondered if
                I would be more relaxed without the pressure to socially
                perform. The format is very autism-friendly, after all: there is
                a set schedule, it's quiet, eye contact is discouraged and
                talking is prohibited. Still, I felt this strong sense of
                alienation from the human beings that surrounded me. I took my
                breakfast outside, enjoying the quiet and relaxing experience of
                eating alone in nature.
              </p>
              <p>
                Sitting meditations were done in the hall, with everyone seated
                on cushions or benches or chairs. We were instructed to be aware
                of our sensations and thoughts as we meditated. I focused on my
                breath; on the rising and falling of my chest. I focused on the
                sound of the air coniditoner, and how it was composed of both
                the hum of the fan and the rattle of the grate. I focused on the
                tension in my arms, which would not relent. I lost focus and
                noticed when it came back again
              </p>
              <p>
                I did my walking meditations out in the forest. A fallen log
                formed a natural bridge over a stream, bracketed on either side
                by redwoods. Here I stepped deliberately, one pace at a time,
                across a span of about 5 meters, trying to take note of how the
                ground felt beneath my feet. The fresh air and quiet sounds of
                nature soothed me. I contemplated selling my condo to go and
                live in the woods, or joining a retreat center such as this one.
                I thought about my life back at home. I thought about a lot of
                things, trying at least to be aware of when I was thinking
              </p>
              <p>
                By the time lunch came around, I was in a state of somewhat
                heightened sensitivity. Spending the entire morning in
                meditation honed my awareness, such that when I entered the
                dining hall I was greeted with the most delicious smell of green
                beans and curry. I piled my plate up high with vegetables and
                quinoa (the food was all vegetarian, and mostly vegan). I sat
                outside under a huge redwood tree and slowly took a bite of a
                green bean. It was the most delicious thing I had ever eaten.{" "}
                <i>Humans made this food</i>, I thought to myself.{" "}
                <i>Perhaps they are not so bad.</i>
              </p>
              <p>
                After lunch there was free time to spend as we pleased. I sat
                down by the large pond and watched the pondskaters flit across
                the surface of the water, making ripples in the reflections of
                the trees overhead. The weather was unusually warm for the PNW
                that day, and absolutely the perfect temperature for me. As I
                contemplated, I wondered what it would be like to only allow
                myself to use the I/me pronouns.
                <i>Look at that pondskater go</i>, I thought.{" "}
                <i>I am very nimble in the way I flit across the water</i>.
                Passing a sturdy redwood I thought,{" "}
                <i>Look how strong I am growing.</i> I felt the barriers between
                all things fall away, and felt a sense of unity with the entire
                world. A warmth permeated my body, and I floated lightly on my
                feet. I watched peacefully as a mosquito bit my leg and took a
                blood meal, watching my segmented abdomen fill with my own
                blood.
                <i>
                  This is great, I have achieved peak retreat brain, and it's
                  only the first day.
                </i>
              </p>
              <p>
                This lasted until the first group session, when I opened my
                mouth and all the issues I had been having came pouring out of
                my mouth. A strong sense that it wasn't good enough for me to
                achieve peace on this retreat if I couldn't share it with
                others. The involuntary tension in my body around human beings.
                My sore lower back from meditating on a bench.
              </p>
              <p>
                It dawned on me that I had not, in fact, won the retreat, but
                rather experienced a state of mind that, while very blissful,
                was like all things, impermanent.
              </p>
            </Box>
            <ImageCard
              url="/assets/images/cloud_mountain_trees.png"
              altText="A redwood forest. There are numerous trees and ferns"
              caption="I will repeat it as many times as necessary: the forest was absolutely beautiful"
            />
          </Stack>
          <Box>
            <h2>Day Two</h2>
            <p>
              On day two the instructor Kerri opened the day by speaking about
              the nature of mindfulness, which is about understanding the
              present moment as it is. One phrase stuck with me: "What is here".
            </p>
            <p>
              As the afternoon went on, it became clear to me what the answer to
              that question was; complete and total exhaustion. I decided I was
              going to talk to the instructors. I knew I didn't want to go home,
              but I was going to need to sit out some of the meditations to
              catch up on sleep. My body is, after all, unusually weak and
              frail. I was afraid it would not be able to adjust to getting less
              sleep, and I was right. I felt fatigued, I had a headache, and was
              even getting slightly nauseous.
            </p>
            <p>Then I ate dinner and I felt a lot better</p>
            <p>
              I remebered that sometimes when I am hungry, instead of sending "I
              am hungry" signals to my brain, my body instead sends "I am
              utterly exhausted" signals. My exhausted state, just like my
              blissful state the previous day, was impermanent.
            </p>
            <p>
              That evening the second instructor, Shelley arrived and spoke for
              the first time, as their flight had been delayed for multiple
              days. They spoke of their experience at the airport, and how
              uplifting it was to see people show each other grace and
              compassion, even as they are hungry and tired and perhaps only
              hanging on by a thread. Their airport experience was not pleasant,
              but they said that any sensation at all, even unpleasant ones, are
              simply something being known.
            </p>
            <p>
              Shelly had emphasized that mindfulness is about being aware of
              what it is like to experience the present moment. What it is like
              to be the being that is having these sensations. Before going to
              bed, I made a note to myself. "What's it like to be this one?"
            </p>
          </Box>
          <Stack direction={"row"} alignItems={"center"}>
            <ImageCard
              url="/assets/images/cloud_mountain_notes.png"
              altText="A blue sheet of paper with many handwritten notes on it. Circled in black are the phrases 'What's it like to be this one?' and 'It's like this'"
              caption="Possible tattoo idea?"
            />
            <Box>
              <h2>Day Three</h2>
              <p>
                I had my group session with Shelly that day. One of the group
                members brought up their talk from the previous night. A phrase
                came up that resonated strongly.
                <br />
                <br />
                <i>It's like this.</i>
                <br />
                <i>It's like this.</i>
                <br />
                <i>It's like this.</i>
              </p>
              <p>
                So moved I was by this phrase that I wrote it down on my scratch
                note paper. As I looked at my notes, I realized I had written
                down a perfect call-and-response, without even trying.
              </p>
              <br></br>
              <p>What's it like to be this one?</p>
              <p>It's like this.</p>
            </Box>
          </Stack>
          <Stack direction="row" alignItems={"center"}>
            <Box>
              <h2>Intermission: Forest Gate</h2>
              <p>
                That day after lunch I decided to take an adventure into the
                woods. I was energized - perhaps a little too energized from
                taking my Adderall later in the day than usual. I entered the
                forest trail loop with the intention to explore and came across
                a pair of trees bent in such a way as to make a sort of gate or
                doorway.
              </p>
              <p>
                What would you do if you saw a gate in the middle of the woods?
                I know what I did - I went right on through!{" "}
              </p>
              <p>
                No sooner had I taken three steps than I heard a loud rustle and
                a thud. An animal made that noise - a big one. Big enough to be
                a cougar - we were warned that they were in the area. A cougar
                wouldn't make that much noise though. So I stood and waited.
              </p>
              <p>And you know what I heard?</p>
              <p>Nothing.</p>
              <p>
                So I turned tail and ran back away from the forest to the safety
                and comfort of other people in an interesting inversion of what
                I had been doing previously. I did not return to the forest for
                the rest of the day
              </p>
            </Box>
            <ImageCard
              url="/assets/images/fairy_gate.png"
              altText="Several trees in a redwood forest that are bending over and touching to form an arch"
              caption="Remember kids: always jump through the portal to the fairy realm"
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <ImageCard
              url="/assets/images/mosquito_pond.png"
              altText="A large pond bordered by trees"
              caption="The pond was my favorite spot to eat meals at"
            />
            <Box>
              <h2>Mosquito meditation</h2>
              <p>
                The Buddha taught meditation in 4 positions - walking, standing,
                sitting, and lying down. That evening I was quite tired of
                sitting and decided to try a meditation lying down outside. I
                thought it would be easier, but shortly after getting situated I
                was beset by mosquitos buzzing in my ear. I had been letting
                them feed on my arms and legs, but shooing them away if they
                buzzed near my face. I figured now would be a good time to
                practice sitting (rather, lying down) with discomfort, so this
                time I allowed the mosquitos to do as they pleased.
              </p>
              <p>
                As the mosquitos buzzed my ears I lay perfectly still and tried
                to be aware of the experience. I noticed that each mosqiuto
                buzzed at a slightly different pitch. I noticed the shiver that
                got sent through the entire left side of my body as a mosquito
                landed on my left cheek. I noticed the slight prick as the
                mosquito injected its proboscis which faded away as the numbing
                properties of mosquito saliva took effect. By the end of the
                sitting I was even starting to enjoy it slightly. Imagine that,
                actually enjoying mosquito bites!
              </p>
            </Box>
          </Stack>
          <Stack direction={"row"} alignContent={"center"}>
            <Box>
              <h2>Day Four</h2>
              <p>
                Day four was rough. The magic of nature was beginning to wear
                off, and I was actually starting to get kind of bored. On top
                that it was freezing cold that morning. I was grumpy, hungry,
                and ready to go home. But, still, I sat my meditations
                dutifully.
              </p>
              <p>
                Important insights revealed themself to me in my head; I thought
                of the challenges I was facing back home and realized I had to
                make major changes. A mindfully took note of the sense of fear
                that crept over me. I took a walking meditation out in the
                woods, and a frog kept me company as I silently cried with grief
                over things I had yet to lose.
              </p>
              <p>Then I are lunch and I felt a lot better.</p>
              <p>
                At this point a pattern was making itself clear. Time and again
                I told myself stories about my life back home. Sometimes they
                were hopeful, with happy endings. Sometimes they were fearful
                and tragic. And the number one predictor about which ending I
                would get? How hungry I was. These stories had no basis in
                reality; no new information was presenting itself to me. My
                thoughts followed one after other, and I was so sure I was
                making progress, but really I was just thinking myself in
                circles. Thoughts feel so important and insightful but often
                they're really not productive at all.
              </p>
            </Box>
            <ImageCard
              url="/assets/images/walking_meditation_path.png"
              altText="A mossy forest path. Sunlight dapples the ground"
              caption="My main walking meditation spot"
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <ImageCard
              url="/assets/images/log_bridge.png"
              altText="A fallen log forming a natural bridge across a stream."
              caption="One more time: the scenery was breathtaking"
            />
            <Box>
              <h2>Day Five</h2>
              <p>
                All the time spent meditating gave me plenty of opportunity to
                take note of my body. I was particularly aware of how tense my
                arms felt in the presence of other people, versus how relaxed
                they felt out in nature. But, I had decided I spent enough time
                hiding from everyone else in the woods. If I could tolerate
                mosquitos biting my face, I could probably tolerate eating
                breakfast around other people
              </p>
              <p>
                I took my oatmeal into the lounge and sat on the couch. As I
                sat, I felt the atmosphere grow a little friendlier. The people
                around me were feeling less like strangers and more like
                friends. My body relaxed, and I felt at ease
              </p>
              <p>
                During the final walking period I took one last walk through the
                woods. I wanted this last walk to feel special. Previous walks
                had a magical, ethereal, floaty quality to them. This one just
                felt... normal. But that's ok; one of the themes of the retreat
                was to experience life as it is, without expectations for
                feeling a certain way.
              </p>
              <p>So, I accepted that this last walk would be... ordinary.</p>
              <p>
                Not a moment later, a buck deer bounded through the greenery
                into view. We gazed at each other for a minute or two before
                parting ways.
              </p>
            </Box>
          </Stack>
          <p>
            Song of the day: The Crane Wife 3 by The Decembrists. I find the
            acoustic guitar very soothing, even if it is a melancholy song
            <br></br>
            <iframe
              height="200"
              src="https://www.youtube.com/embed/jnwRewQ--QM?si=19y70a5IJD_aiFqF"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <blockquote>
              And I will hang my head, hang my head low<br></br>
              And I will hang my head, hang my head low<br></br>
              And I will hang my head, hang my head low<br></br>
              And I will hang my head, hang my head low<br></br>
            </blockquote>
          </p>
        </Stack>
      </div>
    </>
  ),
};
