/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Oct2624: IPost = {
  id: 12,
  title: "Anapanasati Day 2",
  date: new Date("2024/10/26"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        Sat Anapanasati again today. Once again started by labelling breaths
        long or short. Watched the body decide on its own what sort of breath to
        take
      </div>
      <br></br>
      <div>
        Shifting attention to the whole body was challenging. There are so many
        sensations in the body it is hard to hold them all in my mind at once.
        There were a few flashes where my entire focus was on my body, but they
        were few and far between.
      </div>
      <br></br>
      <div>The only thing to do is continue practicing. </div>
    </>
  ),
};
