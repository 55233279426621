import { Canvas } from "../canvas/canvas";
import { Canvas as CanvasType } from 'fabric/fabric-impl';
import {fabric} from 'fabric'
import { Box } from "@mui/material";
import { getRandomInt } from "../common/utils";


enum Direction {
    Up,
    Down,
    Left,
    Right,
  }

interface ICoords {
    x: number,
    y: number,
}

interface ISnakeHead{
    coords: ICoords,
    direction: Direction,
}

let pixelSize = 10


export function Snake() {
    let inputRecordedThisFrame = false
    let gameStarted = false
    let gameOver = false
    let snakeHeadPosition:ISnakeHead = {coords:{x:20, y:20}, direction: Direction.Up}
    let snakeLength = 4

    // Initialize food
    let foodLocation: ICoords = {x: getRandomInt(300/pixelSize), y:getRandomInt(300/pixelSize)}




    let snakeSegments: fabric.Rect[] = []
    // let snakeSegments = [...Array(snakeLength).keys()].map((segNum => {
    //     return new fabric.Rect({
    //         left: (snakeHeadPosition.coords.x * pixelSize),
    //         top: (snakeHeadPosition.coords.y + segNum)*pixelSize,
    //         fill: 'white',
    //         width: pixelSize,
    //         height: pixelSize,
    //       }) 
    // }))

    function clearCanvas(canvas:CanvasType) {
        let rect = new fabric.Rect({
            left: 0,
            top: 0,
            fill: 'black',
            width: canvas.width,
            height: canvas.height,
            selectable: false,
          });
          
          // clear canvas
        canvas.add(rect);
    }
    
    
    function moveSnakeHead(snakeHeadPosition: ISnakeHead) {
        switch(snakeHeadPosition.direction) {
            case Direction.Up:
                snakeHeadPosition.coords.y -= 1
                break
            case Direction.Down:
                snakeHeadPosition.coords.y += 1
                break
            case Direction.Left:
                snakeHeadPosition.coords.x -= 1
                break
            case Direction.Right:
                snakeHeadPosition.coords.x += 1
                break
            default:
                break
        }
    }
    
    function addNewSnakeHead( snakeSegments: fabric.Rect[], snakeHeadPosition: ISnakeHead) {
        snakeSegments.unshift(new fabric.Rect({
            left: (snakeHeadPosition.coords.x * pixelSize),
            top: (snakeHeadPosition.coords.y * pixelSize),
            fill: 'white',
            width: pixelSize,
            height: pixelSize,
          }))
    }

    function calculateEating(canvas: CanvasType, snakeHeadPosition:ISnakeHead, foodLocation:ICoords) {
        if (snakeHeadPosition.coords.x === foodLocation.x && snakeHeadPosition.coords.y === foodLocation.y ) {
            foodLocation.x = getRandomInt((canvas.width as number)/pixelSize)
            foodLocation.y = getRandomInt((canvas.height as number)/pixelSize)
            return true
        }
        return false
    }

    function calculateGameover(canvas: CanvasType, snakeHeadPosition: ISnakeHead, snakeSegments: fabric.Rect[]) {
        let gameOver = false
        if ( snakeHeadPosition.coords.x >= (canvas.width as number)/pixelSize || 
             snakeHeadPosition.coords.x < 0 ||
             snakeHeadPosition.coords.y >= (canvas.height as number)/pixelSize || 
             snakeHeadPosition.coords.y < 0
        ) {
            gameOver = true
        }

        snakeSegments.slice(1).forEach((segment) => {
            if ( ((segment.left as number) / pixelSize) === snakeHeadPosition.coords.x && 
                 ((segment.top as number) / pixelSize) === snakeHeadPosition.coords.y) {
                gameOver = true
            }
        })
        return gameOver
    }

    function drawStartScreen(canvas:CanvasType) {
        canvas.add(new fabric.Text('Snake', { left: 120, top: 50, fill: 'white', fontSize: 20, fontFamily: 'sans-serif'}))
        canvas.add(new fabric.Text('Click to start', { left: 90, top: 100, fill: 'white', fontSize: 20, fontFamily: 'sans-serif'}))
    }

    function drawGameOverScreen(canvas:CanvasType, snakeSegments: fabric.Rect[]) {
        canvas.add(new fabric.Text('You lost!', { left: 90, top: 50, fill: 'white', fontSize: 20, fontFamily: 'sans-serif'}))
        canvas.add(new fabric.Text(`Your score: ${snakeSegments.length - 4}`, { left: 120, top: 100, fill: 'white', fontSize: 20, fontFamily: 'sans-serif'}))

        canvas.add(new fabric.Text('Click to replay', { left: 90, top: 200, fill: 'white', fontSize: 20, fontFamily: 'sans-serif'}))
    }
    
    function drawFrame(canvas:CanvasType) {
        clearCanvas(canvas)

        if (!gameStarted) {
            drawStartScreen(canvas)
            return
        }

        if (gameOver) {
            drawGameOverScreen(canvas, snakeSegments)
        }
        
        inputRecordedThisFrame = false;
        moveSnakeHead(snakeHeadPosition)
        gameOver = calculateGameover(canvas, snakeHeadPosition, snakeSegments)
        if (gameOver) {
            return
        }
        let snakeFed = calculateEating(canvas, snakeHeadPosition, foodLocation)



        if (!snakeFed) {
            snakeSegments.pop()
        }
        addNewSnakeHead(snakeSegments, snakeHeadPosition)
    
        snakeSegments.forEach((segment) => canvas.add(segment))
        let food = new fabric.Rect({
            left: foodLocation.x * pixelSize,
            top: foodLocation.y * pixelSize,
            fill: 'white',
            width: pixelSize,
            height: pixelSize,
        })
        canvas.add(food)

    
    }
        
    function preventDefaultArrowActions(e:KeyboardEvent) {
        if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
        }
    }

    function handleGameStart() {
        gameStarted = true
        gameOver = false
        snakeHeadPosition = {coords:{x:20, y:20}, direction: Direction.Up}
        snakeSegments = [...Array(snakeLength).keys()].map((segNum => {
            return new fabric.Rect({
                left: (snakeHeadPosition.coords.x * pixelSize),
                top: (snakeHeadPosition.coords.y + segNum)*pixelSize,
                fill: 'white',
                width: pixelSize,
                height: pixelSize,
              }) 
        }))
        window.addEventListener("keydown", preventDefaultArrowActions, false);
    }

    function enableArrowScrolling() {
        window.removeEventListener("keydown", preventDefaultArrowActions, false);
    }
    function canvasHook(canvas:CanvasType) {
        let rect = new fabric.Rect({
            left: 0,
            top: 0,
            fill: 'black',
            width: canvas.width,
            height: canvas.height,
            selectable: false,
          });
          
        canvas.add(rect);

        // Register user interactin functions
        window.addEventListener('keydown', function (e) {
            if(inputRecordedThisFrame) {
                return
            }
            let key = e.key;
            switch(key) {
                case 'ArrowUp':
                    if (snakeHeadPosition.direction !== Direction.Down){
                        snakeHeadPosition.direction = Direction.Up
                    }
                    break 
                case 'ArrowDown':
                    if (snakeHeadPosition.direction !== Direction.Up){
                        snakeHeadPosition.direction = Direction.Down
                    }
                    break
                case 'ArrowLeft':
                    if (snakeHeadPosition.direction !== Direction.Right){
                        snakeHeadPosition.direction = Direction.Left
                    }
                    break
                case 'ArrowRight':
                    if (snakeHeadPosition.direction !== Direction.Left){
                        snakeHeadPosition.direction = Direction.Right
                    }
                    break
            }
            inputRecordedThisFrame = true;
          })


        // Register update function
        setInterval(drawFrame, 100, canvas);

    }
    return <>
        <Box onClick={()=>handleGameStart()}>        
            <Canvas canvasHook={canvasHook} height={300} width={300}></Canvas>
        </Box>
    </>
}