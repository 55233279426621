/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Nov224: IPost = {
  id: 18,
  title: "Anapanasati (?) Day 8",
  date: new Date("2024/11/2"),
  tags: ["Journal", "Meditation"],
  body: (
    <>
      <div>
        Today was another unmedicated meditation day, and boy was it hard to
        focus. I tried labelling the breaths long and short like usual but there
        was a 'buzz' in my head and my focus wouldn't settle. I almost gave up
        halfway through but decided to see through to the end. I started
        stimming by rocking back and forth, turning the sit into more of a
        movement practice.
      </div>
      <div>
        My mind kept getting distracted by worldly thoughts and the birds making
        noise outside my window. I tried to make space for it all, the
        restlessness, the thoughts, the birds. It was valuable practice. I
        couldn't wait for the bell to ring.
      </div>
    </>
  ),
};
