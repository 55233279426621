/* eslint-disable jsx-a11y/img-redundant-alt */
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Feb1024: IPost = {
    'id': 6,
    'title': 'Kitty Beanie Baby',
    'date': new Date('2024/2/10'),
    'tags': ['Journal', 'Art'],
    'body':
<>
<div>
    <p>
        Reading: Palestine: A 4000 Year History
        <br></br>
        Playing: Against the Storm
        <br></br>
        Mood: Adjusted
    </p>
    <div style={{'display':'flex'}}>
        <div style={{'flexGrow': 3}}>
            <p>
                I do not control the creative projects I end up working on. When my brain said "hey it's time to turn your cat into a knockoff beanie baby"
                all I could do was go "on it boss". 
            </p>
            <p>
                Song of the day is the forgotten treasure that is the Catz (Nintendo DS) Theme
                <br></br> 
                <iframe  height="200" src="https://www.youtube.com/embed/TSzp6-SV5FI?si=Es3ot7CJp6Ouqu4a" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                
                <blockquote>
                    I am just a kitten<br></br>
                    Hardly fit my mittens<br></br>
                    Much too small I figure<br></br>
                    One day I'll be bigger<br></br>
                </blockquote>
            </p>
        </div>
        <ImageCard 
            url="/assets/images/mimi_baby.jpg" 
            altText="A photo of a black cat and a small handmade plush toy of a black cat"
            caption="Two Mimis is better than one"/>
    </div>
</div>
</>
}