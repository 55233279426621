export function ImageCard({
  url = "",
  caption = "",
  altText = "",
  height = "400px",
  fontSize = "14px",
}) {
  return (
    <div style={{ flexGrow: 1 }}>
      <img
        style={{ flexGrow: 1 }}
        src={url}
        alt={altText}
        height={height}
        title={altText}
      ></img>
      <p style={{ fontSize: fontSize }}>{caption}</p>
    </div>
  );
}
