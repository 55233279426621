import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LandingPage } from "./landingPage";
import { Writing } from "./writing/writing";
import { Blog } from "./blog";
import { Posts } from "./blog/posts";
import { SinglePost } from "./blog/singlePost";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<LandingPage></LandingPage>}></Route>
          <Route path="/writing" element={<Writing></Writing>}></Route>
          <Route path="/blog/" element={<Blog></Blog>}></Route>
          <Route path="/blog/tagged/:tag" element={<Blog></Blog>}></Route>
          <Route path="/blog/:id" element={<SinglePost></SinglePost>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
