import { IPost } from "../types";

export let Nov1523: IPost = {
    'id': 1,
    'title': 'Curse you Tiktaalik',
    'date': new Date('2023/11/15'),
    'tags': ['Journal'],
    'body':
<>
<div>
    <p>
        Reading: <a href='https://en.wikipedia.org/wiki/House_of_Leaves'><span style={{'color':"#0590ed"}}>House</span> of Leaves</a>
        <br></br>
        Playing: Story of Seasons: A Wonderful Life
        <br></br>
        Mood: Capricious
    </p>
    <div style={{'display':'flex'}}>
        <div style={{'flexGrow': 1}}>
                <img style={{'flexGrow': 1}} src="/assets/images/tiktaalik.jpg" alt="Illustration of Tiktaalik climbing out of water onto land" height="200px"></img>
                <p style={{'fontSize': '14px'}}>I'm blaming all of my problems on you, Tiktaalik</p>
        </div>
        <div style={{'flexGrow': 3}}>
            <p>
                I have a medical condition, and it's called "having knees". If anyone knows of a cure, please let me know. I managed 
                to tweak a hamstring while exercising, the thing I am supposed to do to keep my knees healthy. Knees, man.
                Can't live with them, can't delete them from existence and walk around with really long straight stick legs. 
                Starting to think climbing out of the water 375 million years ago was a mistake
            </p>
            <p>
                Let's keep the mewithoutyou train going with today's Song of the Day, <i>The Fox, the Crow, and the Cookie</i>. This song recounts an old aesop's fable, 
                and it's one of my favorites! I particularly like the bird puns
                <br></br> 
                <iframe title='The Fox, the Crow, and the Cookie' style={{'border':'0','width':'400px','height':'42px' }} src="https://bandcamp.com/EmbeddedPlayer/album=2379879436/size=small/bgcol=000000/linkcol=ffffff/track=3707973561/transparent=true/" seamless><a href="https://mewithoutyou.bandcamp.com/album/its-all-crazy-its-all-false-its-all-a-dream-its-alright">It&#39;s All Crazy! It&#39;s All False! It&#39;s All a Dream! It&#39;s Alright by mewithoutYou</a></iframe>
                <blockquote>
                    We'll rest upon the knee<br></br>
                    Where all divisions cease to be <br></br>
                    And root beer float in our banana boat<br></br>
                    Across the tapioca sea<br></br>
                </blockquote>
            </p>
        </div>
    </div>
</div>
</>
}