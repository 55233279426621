import { IPost } from "../types";

export let Nov2823: IPost = {
    'id': 4,
    'title': '2023 is the year of the custom blogging platform',
    'date': new Date('2023/11/28'),
    'tags': ['Journal', 'Art'],
    'body':
<>
<div>
    <p>
    Reading: <a href='https://en.wikipedia.org/wiki/House_of_Leaves'><span style={{'color':"#0590ed"}}>House</span> of Leaves</a>
        <br></br>
        Playing: Story of Seasons: A Wonderful Life
        <br></br>
        Mood: Frazzled
    </p>
    <div style={{'display':'flex'}}>
        <div style={{'flexGrow': 3}}>
            <p>
                It's been a hot minute since my last blog post, and that is because I have been refactoring my website to have more of a 
                custom blogging platform and less "dump all blog posts in a .tsx file and call it a day". It doesn't really look 
                any different, but trust me, much more organized now. Also the blog titles are clickable now! But uhh, don't click them,
                the routing doesn't work yet. 
            </p>
            <p>
                I had a bunch more improvements I wanted to make (such as for-real clickable blog titles, tags, and a post list) but it was taking forever and I 
                was getting discouraged so fuck it, we push straight to prod! The rest will come later.... right?
            </p>
            <p>
                In other news this week I designed a fan neopet: the Aerop, a dog-plan creature. It was originally just supposed to be 
                a meme but it's honestly grown on me. In honor of the Aerop, today's Song of the Day is "at the airport terminal" by Bill Wurtz 
                aka the greatest artist of our generation.
                <br></br> 
                <iframe  height="200" src="https://www.youtube.com/embed/q-qqrGtlHkg?si=MVIZUTwjLzeHjjoI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                
                <blockquote>
                    At the airpost terminal the planes are landing<br></br>
                    BrrRRrrrRRRrrRRRRRrrrrRRRrrr<br></br>
                    One day soon we will all be togther<br></br>
                    Until then the terminal carries me through<br></br>
                </blockquote>
            </p>
        </div>
        <div style={{'flexGrow': 1}}>
            <img style={{'flexGrow': 1}} src="/assets/images/aerops_default.jpg" alt="Four plane dog creatures. They are white airplanes with colorful wings and tails. The four creatures are red, green, blue, and yellow" height="400px"></img>
            <p style={{'fontSize': '14px'}}>Behold, the Aerop. A friendly flying creature that eats only the finest jet fuel</p>
        </div>
    </div>
</div>
</>
}